.searchdialog-wrapper .nav{
    display: flex;
    flex-wrap: nowrap !important;
    overflow: auto !important;
    padding-left: 0;
    margin-bottom: 0;
    height: 100% !important;
    list-style: none;
}

.searchdialog-header a, .searchdialog-header a:hover{
  text-decoration: none !important;
}

.searchdialog-wrapper {
    position: fixed;
    display: none;
    background-color: white;
    border: 0.5px solid #05164d;
    bottom: 170px;
    overflow: hidden;
    right: 25px !important;
}

.searchdialog-wrapper h3 {
  font-size: 14px;  
  font-weight: bold;
  color: #05164d;
  margin-top: 0;
  margin-bottom: 5px;
}

.searchdialog-wrapper h5 {
  font-size: 12px;
  font-weight: normal;
  color: #05164d;
  margin-top: 2px;
  margin-bottom: 2px;
}

.searchdialog-wrapper .text-content {
    font-size: 12px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.searchdialog-content {
    width: calc((100vw/4)*3);
    height: 550px;
    overflow: hidden;
    position: relative;
}

.searchdialog-content em {
  background: #ffda26;
}

.searchdialog-header-wrapper {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background-color: #05164d;
}

.searchdialog-header {
    padding: 3px;
    display: flex;
    list-style: none;
}

.searchdialog-header li > a {
    padding: 3px 20px;
    color: white;
    width: auto;
    text-align: center;
    margin-left: 20px;
    margin-right: 20px;
    min-width: 100px;
    border-bottom: 2px solid white;
    font-weight: bold;
    cursor: pointer;
}

.searchdialog-header li > a:hover{
    color: #00afcb;
}

.searchdialog-header li > a[aria-selected="true"]{
  border-bottom: 4px solid #00afcb;
}

.searchdialog-searches{
    padding-top: 15px;
}

.searchdialog-wrapper .result-item {
    display: flex;
    margin-top: 0px;
    margin-bottom: 6px;
    cursor: pointer;
    border-bottom: 1px dotted #ddd;
    padding: 4px 0;
}

.searchdialog-wrapper .result-item:hover {
  background: #ddd;
}

.searchdialog-wrapper .result-list{
    height: 470px;
    overflow: auto;
}

.searchdialog-wrapper .result-icon{
    margin-right: 10px;
    min-width: 60px;
}

.searchdialog-wrapper .result-body{
    display: grid;
}

.searchdialog-wrapper .searchdialog-icon{
    width: 60px;
}

.searchdialog-icon.contact-icon{
    width: 60px;
}
.result-icon .result-type {
  width: 60px;
  text-align: center;
  font-size: 10px;
  font-weight: bold;
  overflow: hidden;
  line-height: 16px;
}

.searchdialog-wrapper .row {
    margin: 0px !important;
}

.close-dialog {
    position: absolute;
    cursor: pointer;
    right: 10px;
    color: white;
    z-index: 10;
    margin-top: -15px;
}
.close-dialog:hover svg {
    fill: #00afcb;
}

.close-dialog-bg {
  position: absolute;
  cursor: pointer;
  right: -1px;
  width: 40px;
  height: 41px;
  background-color: #05164d;
  margin-top: -10px;
}

@media (max-width:641px)  { 
  .searchdialog-content {
    width: calc((90vw/4)*4);
    height: 550px;
    overflow: hidden;
    position: relative;
  }

  .searchdialog-wrapper {
    top: 54px !important;
    right: 10px !important;
  }
}

@media (max-width: 1100px) {
  .searchdialog-header-wrapper {
    justify-content: flex-start !important;
    overflow-x: auto !important;
    height: 60px;
  }
}





/* BOOTSTRAP GRID CSS */
@-ms-viewport {
    width: device-width;
  }
  
  html {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    -ms-overflow-style: scrollbar;
  }
  
  *,
  *::before,
  *::after {
    -webkit-box-sizing: inherit;
            box-sizing: inherit;
  }
  
  .container {
    margin-right: auto;
    margin-left: auto;
    padding-right: 15px;
    padding-left: 15px;
  }
  
  @media (min-width: 576px) {
    .container {
      padding-right: 15px;
      padding-left: 15px;
    }
  }
  
  @media (min-width: 768px) {
    .container {
      padding-right: 15px;
      padding-left: 15px;
    }
  }
  
  @media (min-width: 992px) {
    .container {
      padding-right: 15px;
      padding-left: 15px;
    }
  }
  
  @media (min-width: 1200px) {
    .container {
      padding-right: 15px;
      padding-left: 15px;
    }
  }
  
  @media (min-width: 576px) {
    .container {
      width: 540px;
      max-width: 100%;
    }
  }
  
  @media (min-width: 768px) {
    .container {
      width: 720px;
      max-width: 100%;
    }
  }
  
  @media (min-width: 992px) {
    .container {
      width: 960px;
      max-width: 100%;
    }
  }
  
  @media (min-width: 1200px) {
    .container {
      width: 1140px;
      max-width: 100%;
    }
  }
  
  .container-fluid {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding-right: 15px;
    padding-left: 15px;
  }
  
  @media (min-width: 576px) {
    .container-fluid {
      padding-right: 15px;
      padding-left: 15px;
    }
  }
  
  @media (min-width: 768px) {
    .container-fluid {
      padding-right: 15px;
      padding-left: 15px;
    }
  }
  
  @media (min-width: 992px) {
    .container-fluid {
      padding-right: 15px;
      padding-left: 15px;
    }
  }
  
  @media (min-width: 1200px) {
    .container-fluid {
      padding-right: 15px;
      padding-left: 15px;
    }
  }
  
  .row {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  
  @media (min-width: 576px) {
    .row {
      margin-right: -15px;
      margin-left: -15px;
    }
  }
  
  @media (min-width: 768px) {
    .row {
      margin-right: -15px;
      margin-left: -15px;
    }
  }
  
  @media (min-width: 992px) {
    .row {
      margin-right: -15px;
      margin-left: -15px;
    }
  }
  
  @media (min-width: 1200px) {
    .row {
      margin-right: -15px;
      margin-left: -15px;
    }
  }
  
  .no-gutters {
    margin-right: 0;
    margin-left: 0;
  }
  
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
  }
  
  .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
  .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
  .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
  .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
  .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
  .col-xl-auto {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
  }
  
  @media (min-width: 576px) {
    .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
    .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
    .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
    .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
    .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
    .col-xl-auto {
      padding-right: 15px;
      padding-left: 15px;
    }
  }
  
  @media (min-width: 768px) {
    .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
    .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
    .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
    .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
    .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
    .col-xl-auto {
      padding-right: 15px;
      padding-left: 15px;
    }
  }
  
  @media (min-width: 992px) {
    .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
    .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
    .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
    .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
    .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
    .col-xl-auto {
      padding-right: 15px;
      padding-left: 15px;
    }
  }
  
  @media (min-width: 1200px) {
    .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
    .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
    .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
    .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
    .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
    .col-xl-auto {
      padding-right: 15px;
      padding-left: 15px;
    }
  }
  
  .col {
    -webkit-flex-basis: 0;
        -ms-flex-preferred-size: 0;
            flex-basis: 0;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    max-width: 100%;
  }
  
  .col-auto {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  
  .col-1 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 8.333333%;
        -ms-flex: 0 0 8.333333%;
            flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  
  .col-2 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 16.666667%;
        -ms-flex: 0 0 16.666667%;
            flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  
  .col-3 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 25%;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%;
  }
  
  .col-4 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.333333%;
        -ms-flex: 0 0 33.333333%;
            flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  
  .col-5 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 41.666667%;
        -ms-flex: 0 0 41.666667%;
            flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  
  .col-6 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%;
  }
  
  .col-7 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 58.333333%;
        -ms-flex: 0 0 58.333333%;
            flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  
  .col-8 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 66.666667%;
        -ms-flex: 0 0 66.666667%;
            flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  
  .col-9 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 75%;
        -ms-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%;
  }
  
  .col-10 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 83.333333%;
        -ms-flex: 0 0 83.333333%;
            flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  
  .col-11 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 91.666667%;
        -ms-flex: 0 0 91.666667%;
            flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  
  .col-12 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%;
  }
  
  .pull-0 {
    right: auto;
  }
  
  .pull-1 {
    right: 8.333333%;
  }
  
  .pull-2 {
    right: 16.666667%;
  }
  
  .pull-3 {
    right: 25%;
  }
  
  .pull-4 {
    right: 33.333333%;
  }
  
  .pull-5 {
    right: 41.666667%;
  }
  
  .pull-6 {
    right: 50%;
  }
  
  .pull-7 {
    right: 58.333333%;
  }
  
  .pull-8 {
    right: 66.666667%;
  }
  
  .pull-9 {
    right: 75%;
  }
  
  .pull-10 {
    right: 83.333333%;
  }
  
  .pull-11 {
    right: 91.666667%;
  }
  
  .pull-12 {
    right: 100%;
  }
  
  .push-0 {
    left: auto;
  }
  
  .push-1 {
    left: 8.333333%;
  }
  
  .push-2 {
    left: 16.666667%;
  }
  
  .push-3 {
    left: 25%;
  }
  
  .push-4 {
    left: 33.333333%;
  }
  
  .push-5 {
    left: 41.666667%;
  }
  
  .push-6 {
    left: 50%;
  }
  
  .push-7 {
    left: 58.333333%;
  }
  
  .push-8 {
    left: 66.666667%;
  }
  
  .push-9 {
    left: 75%;
  }
  
  .push-10 {
    left: 83.333333%;
  }
  
  .push-11 {
    left: 91.666667%;
  }
  
  .push-12 {
    left: 100%;
  }
  
  .offset-1 {
    margin-left: 8.333333%;
  }
  
  .offset-2 {
    margin-left: 16.666667%;
  }
  
  .offset-3 {
    margin-left: 25%;
  }
  
  .offset-4 {
    margin-left: 33.333333%;
  }
  
  .offset-5 {
    margin-left: 41.666667%;
  }
  
  .offset-6 {
    margin-left: 50%;
  }
  
  .offset-7 {
    margin-left: 58.333333%;
  }
  
  .offset-8 {
    margin-left: 66.666667%;
  }
  
  .offset-9 {
    margin-left: 75%;
  }
  
  .offset-10 {
    margin-left: 83.333333%;
  }
  
  .offset-11 {
    margin-left: 91.666667%;
  }
  
  @media (min-width: 576px) {
    .col-sm {
      -webkit-flex-basis: 0;
          -ms-flex-preferred-size: 0;
              flex-basis: 0;
      -webkit-box-flex: 1;
      -webkit-flex-grow: 1;
          -ms-flex-positive: 1;
              flex-grow: 1;
      max-width: 100%;
    }
    .col-sm-auto {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 auto;
          -ms-flex: 0 0 auto;
              flex: 0 0 auto;
      width: auto;
      max-width: none;
    }
    .col-sm-1 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 8.333333%;
          -ms-flex: 0 0 8.333333%;
              flex: 0 0 8.333333%;
      max-width: 8.333333%;
    }
    .col-sm-2 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 16.666667%;
          -ms-flex: 0 0 16.666667%;
              flex: 0 0 16.666667%;
      max-width: 16.666667%;
    }
    .col-sm-3 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 25%;
          -ms-flex: 0 0 25%;
              flex: 0 0 25%;
      max-width: 25%;
    }
    .col-sm-4 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 33.333333%;
          -ms-flex: 0 0 33.333333%;
              flex: 0 0 33.333333%;
      max-width: 33.333333%;
    }
    .col-sm-5 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 41.666667%;
          -ms-flex: 0 0 41.666667%;
              flex: 0 0 41.666667%;
      max-width: 41.666667%;
    }
    .col-sm-6 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 50%;
          -ms-flex: 0 0 50%;
              flex: 0 0 50%;
      max-width: 50%;
    }
    .col-sm-7 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 58.333333%;
          -ms-flex: 0 0 58.333333%;
              flex: 0 0 58.333333%;
      max-width: 58.333333%;
    }
    .col-sm-8 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 66.666667%;
          -ms-flex: 0 0 66.666667%;
              flex: 0 0 66.666667%;
      max-width: 66.666667%;
    }
    .col-sm-9 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 75%;
          -ms-flex: 0 0 75%;
              flex: 0 0 75%;
      max-width: 75%;
    }
    .col-sm-10 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 83.333333%;
          -ms-flex: 0 0 83.333333%;
              flex: 0 0 83.333333%;
      max-width: 83.333333%;
    }
    .col-sm-11 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 91.666667%;
          -ms-flex: 0 0 91.666667%;
              flex: 0 0 91.666667%;
      max-width: 91.666667%;
    }
    .col-sm-12 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 100%;
          -ms-flex: 0 0 100%;
              flex: 0 0 100%;
      max-width: 100%;
    }
    .pull-sm-0 {
      right: auto;
    }
    .pull-sm-1 {
      right: 8.333333%;
    }
    .pull-sm-2 {
      right: 16.666667%;
    }
    .pull-sm-3 {
      right: 25%;
    }
    .pull-sm-4 {
      right: 33.333333%;
    }
    .pull-sm-5 {
      right: 41.666667%;
    }
    .pull-sm-6 {
      right: 50%;
    }
    .pull-sm-7 {
      right: 58.333333%;
    }
    .pull-sm-8 {
      right: 66.666667%;
    }
    .pull-sm-9 {
      right: 75%;
    }
    .pull-sm-10 {
      right: 83.333333%;
    }
    .pull-sm-11 {
      right: 91.666667%;
    }
    .pull-sm-12 {
      right: 100%;
    }
    .push-sm-0 {
      left: auto;
    }
    .push-sm-1 {
      left: 8.333333%;
    }
    .push-sm-2 {
      left: 16.666667%;
    }
    .push-sm-3 {
      left: 25%;
    }
    .push-sm-4 {
      left: 33.333333%;
    }
    .push-sm-5 {
      left: 41.666667%;
    }
    .push-sm-6 {
      left: 50%;
    }
    .push-sm-7 {
      left: 58.333333%;
    }
    .push-sm-8 {
      left: 66.666667%;
    }
    .push-sm-9 {
      left: 75%;
    }
    .push-sm-10 {
      left: 83.333333%;
    }
    .push-sm-11 {
      left: 91.666667%;
    }
    .push-sm-12 {
      left: 100%;
    }
    .offset-sm-0 {
      margin-left: 0%;
    }
    .offset-sm-1 {
      margin-left: 8.333333%;
    }
    .offset-sm-2 {
      margin-left: 16.666667%;
    }
    .offset-sm-3 {
      margin-left: 25%;
    }
    .offset-sm-4 {
      margin-left: 33.333333%;
    }
    .offset-sm-5 {
      margin-left: 41.666667%;
    }
    .offset-sm-6 {
      margin-left: 50%;
    }
    .offset-sm-7 {
      margin-left: 58.333333%;
    }
    .offset-sm-8 {
      margin-left: 66.666667%;
    }
    .offset-sm-9 {
      margin-left: 75%;
    }
    .offset-sm-10 {
      margin-left: 83.333333%;
    }
    .offset-sm-11 {
      margin-left: 91.666667%;
    }
  }
  
  @media (min-width: 768px) {
    .col-md {
      -webkit-flex-basis: 0;
          -ms-flex-preferred-size: 0;
              flex-basis: 0;
      -webkit-box-flex: 1;
      -webkit-flex-grow: 1;
          -ms-flex-positive: 1;
              flex-grow: 1;
      max-width: 100%;
    }
    .col-md-auto {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 auto;
          -ms-flex: 0 0 auto;
              flex: 0 0 auto;
      width: auto;
      max-width: none;
    }
    .col-md-1 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 8.333333%;
          -ms-flex: 0 0 8.333333%;
              flex: 0 0 8.333333%;
      max-width: 8.333333%;
    }
    .col-md-2 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 16.666667%;
          -ms-flex: 0 0 16.666667%;
              flex: 0 0 16.666667%;
      max-width: 16.666667%;
    }
    .col-md-3 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 25%;
          -ms-flex: 0 0 25%;
              flex: 0 0 25%;
      max-width: 25%;
    }
    .col-md-4 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 33.333333%;
          -ms-flex: 0 0 33.333333%;
              flex: 0 0 33.333333%;
      max-width: 33.333333%;
    }
    .col-md-5 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 41.666667%;
          -ms-flex: 0 0 41.666667%;
              flex: 0 0 41.666667%;
      max-width: 41.666667%;
    }
    .col-md-6 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 50%;
          -ms-flex: 0 0 50%;
              flex: 0 0 50%;
      max-width: 50%;
    }
    .col-md-7 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 58.333333%;
          -ms-flex: 0 0 58.333333%;
              flex: 0 0 58.333333%;
      max-width: 58.333333%;
    }
    .col-md-8 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 66.666667%;
          -ms-flex: 0 0 66.666667%;
              flex: 0 0 66.666667%;
      max-width: 66.666667%;
    }
    .col-md-9 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 75%;
          -ms-flex: 0 0 75%;
              flex: 0 0 75%;
      max-width: 75%;
    }
    .col-md-10 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 83.333333%;
          -ms-flex: 0 0 83.333333%;
              flex: 0 0 83.333333%;
      max-width: 83.333333%;
    }
    .col-md-11 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 91.666667%;
          -ms-flex: 0 0 91.666667%;
              flex: 0 0 91.666667%;
      max-width: 91.666667%;
    }
    .col-md-12 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 100%;
          -ms-flex: 0 0 100%;
              flex: 0 0 100%;
      max-width: 100%;
    }
    .pull-md-0 {
      right: auto;
    }
    .pull-md-1 {
      right: 8.333333%;
    }
    .pull-md-2 {
      right: 16.666667%;
    }
    .pull-md-3 {
      right: 25%;
    }
    .pull-md-4 {
      right: 33.333333%;
    }
    .pull-md-5 {
      right: 41.666667%;
    }
    .pull-md-6 {
      right: 50%;
    }
    .pull-md-7 {
      right: 58.333333%;
    }
    .pull-md-8 {
      right: 66.666667%;
    }
    .pull-md-9 {
      right: 75%;
    }
    .pull-md-10 {
      right: 83.333333%;
    }
    .pull-md-11 {
      right: 91.666667%;
    }
    .pull-md-12 {
      right: 100%;
    }
    .push-md-0 {
      left: auto;
    }
    .push-md-1 {
      left: 8.333333%;
    }
    .push-md-2 {
      left: 16.666667%;
    }
    .push-md-3 {
      left: 25%;
    }
    .push-md-4 {
      left: 33.333333%;
    }
    .push-md-5 {
      left: 41.666667%;
    }
    .push-md-6 {
      left: 50%;
    }
    .push-md-7 {
      left: 58.333333%;
    }
    .push-md-8 {
      left: 66.666667%;
    }
    .push-md-9 {
      left: 75%;
    }
    .push-md-10 {
      left: 83.333333%;
    }
    .push-md-11 {
      left: 91.666667%;
    }
    .push-md-12 {
      left: 100%;
    }
    .offset-md-0 {
      margin-left: 0%;
    }
    .offset-md-1 {
      margin-left: 8.333333%;
    }
    .offset-md-2 {
      margin-left: 16.666667%;
    }
    .offset-md-3 {
      margin-left: 25%;
    }
    .offset-md-4 {
      margin-left: 33.333333%;
    }
    .offset-md-5 {
      margin-left: 41.666667%;
    }
    .offset-md-6 {
      margin-left: 50%;
    }
    .offset-md-7 {
      margin-left: 58.333333%;
    }
    .offset-md-8 {
      margin-left: 66.666667%;
    }
    .offset-md-9 {
      margin-left: 75%;
    }
    .offset-md-10 {
      margin-left: 83.333333%;
    }
    .offset-md-11 {
      margin-left: 91.666667%;
    }
  }
  
  @media (min-width: 992px) {
    .col-lg {
      -webkit-flex-basis: 0;
          -ms-flex-preferred-size: 0;
              flex-basis: 0;
      -webkit-box-flex: 1;
      -webkit-flex-grow: 1;
          -ms-flex-positive: 1;
              flex-grow: 1;
      max-width: 100%;
    }
    .col-lg-auto {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 auto;
          -ms-flex: 0 0 auto;
              flex: 0 0 auto;
      width: auto;
      max-width: none;
    }
    .col-lg-1 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 8.333333%;
          -ms-flex: 0 0 8.333333%;
              flex: 0 0 8.333333%;
      max-width: 8.333333%;
    }
    .col-lg-2 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 16.666667%;
          -ms-flex: 0 0 16.666667%;
              flex: 0 0 16.666667%;
      max-width: 16.666667%;
    }
    .col-lg-3 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 25%;
          -ms-flex: 0 0 25%;
              flex: 0 0 25%;
      max-width: 25%;
    }
    .col-lg-4 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 33.333333%;
          -ms-flex: 0 0 33.333333%;
              flex: 0 0 33.333333%;
      max-width: 33.333333%;
    }
    .col-lg-5 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 41.666667%;
          -ms-flex: 0 0 41.666667%;
              flex: 0 0 41.666667%;
      max-width: 41.666667%;
    }
    .col-lg-6 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 50%;
          -ms-flex: 0 0 50%;
              flex: 0 0 50%;
      max-width: 50%;
    }
    .col-lg-7 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 58.333333%;
          -ms-flex: 0 0 58.333333%;
              flex: 0 0 58.333333%;
      max-width: 58.333333%;
    }
    .col-lg-8 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 66.666667%;
          -ms-flex: 0 0 66.666667%;
              flex: 0 0 66.666667%;
      max-width: 66.666667%;
    }
    .col-lg-9 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 75%;
          -ms-flex: 0 0 75%;
              flex: 0 0 75%;
      max-width: 75%;
    }
    .col-lg-10 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 83.333333%;
          -ms-flex: 0 0 83.333333%;
              flex: 0 0 83.333333%;
      max-width: 83.333333%;
    }
    .col-lg-11 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 91.666667%;
          -ms-flex: 0 0 91.666667%;
              flex: 0 0 91.666667%;
      max-width: 91.666667%;
    }
    .col-lg-12 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 100%;
          -ms-flex: 0 0 100%;
              flex: 0 0 100%;
      max-width: 100%;
    }
    .pull-lg-0 {
      right: auto;
    }
    .pull-lg-1 {
      right: 8.333333%;
    }
    .pull-lg-2 {
      right: 16.666667%;
    }
    .pull-lg-3 {
      right: 25%;
    }
    .pull-lg-4 {
      right: 33.333333%;
    }
    .pull-lg-5 {
      right: 41.666667%;
    }
    .pull-lg-6 {
      right: 50%;
    }
    .pull-lg-7 {
      right: 58.333333%;
    }
    .pull-lg-8 {
      right: 66.666667%;
    }
    .pull-lg-9 {
      right: 75%;
    }
    .pull-lg-10 {
      right: 83.333333%;
    }
    .pull-lg-11 {
      right: 91.666667%;
    }
    .pull-lg-12 {
      right: 100%;
    }
    .push-lg-0 {
      left: auto;
    }
    .push-lg-1 {
      left: 8.333333%;
    }
    .push-lg-2 {
      left: 16.666667%;
    }
    .push-lg-3 {
      left: 25%;
    }
    .push-lg-4 {
      left: 33.333333%;
    }
    .push-lg-5 {
      left: 41.666667%;
    }
    .push-lg-6 {
      left: 50%;
    }
    .push-lg-7 {
      left: 58.333333%;
    }
    .push-lg-8 {
      left: 66.666667%;
    }
    .push-lg-9 {
      left: 75%;
    }
    .push-lg-10 {
      left: 83.333333%;
    }
    .push-lg-11 {
      left: 91.666667%;
    }
    .push-lg-12 {
      left: 100%;
    }
    .offset-lg-0 {
      margin-left: 0%;
    }
    .offset-lg-1 {
      margin-left: 8.333333%;
    }
    .offset-lg-2 {
      margin-left: 16.666667%;
    }
    .offset-lg-3 {
      margin-left: 25%;
    }
    .offset-lg-4 {
      margin-left: 33.333333%;
    }
    .offset-lg-5 {
      margin-left: 41.666667%;
    }
    .offset-lg-6 {
      margin-left: 50%;
    }
    .offset-lg-7 {
      margin-left: 58.333333%;
    }
    .offset-lg-8 {
      margin-left: 66.666667%;
    }
    .offset-lg-9 {
      margin-left: 75%;
    }
    .offset-lg-10 {
      margin-left: 83.333333%;
    }
    .offset-lg-11 {
      margin-left: 91.666667%;
    }
  }
  
  @media (min-width: 1200px) {
    .col-xl {
      -webkit-flex-basis: 0;
          -ms-flex-preferred-size: 0;
              flex-basis: 0;
      -webkit-box-flex: 1;
      -webkit-flex-grow: 1;
          -ms-flex-positive: 1;
              flex-grow: 1;
      max-width: 100%;
    }
    .col-xl-auto {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 auto;
          -ms-flex: 0 0 auto;
              flex: 0 0 auto;
      width: auto;
      max-width: none;
    }
    .col-xl-1 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 8.333333%;
          -ms-flex: 0 0 8.333333%;
              flex: 0 0 8.333333%;
      max-width: 8.333333%;
    }
    .col-xl-2 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 16.666667%;
          -ms-flex: 0 0 16.666667%;
              flex: 0 0 16.666667%;
      max-width: 16.666667%;
    }
    .col-xl-3 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 25%;
          -ms-flex: 0 0 25%;
              flex: 0 0 25%;
      max-width: 25%;
    }
    .col-xl-4 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 33.333333%;
          -ms-flex: 0 0 33.333333%;
              flex: 0 0 33.333333%;
      max-width: 33.333333%;
    }
    .col-xl-5 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 41.666667%;
          -ms-flex: 0 0 41.666667%;
              flex: 0 0 41.666667%;
      max-width: 41.666667%;
    }
    .col-xl-6 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 50%;
          -ms-flex: 0 0 50%;
              flex: 0 0 50%;
      max-width: 50%;
    }
    .col-xl-7 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 58.333333%;
          -ms-flex: 0 0 58.333333%;
              flex: 0 0 58.333333%;
      max-width: 58.333333%;
    }
    .col-xl-8 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 66.666667%;
          -ms-flex: 0 0 66.666667%;
              flex: 0 0 66.666667%;
      max-width: 66.666667%;
    }
    .col-xl-9 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 75%;
          -ms-flex: 0 0 75%;
              flex: 0 0 75%;
      max-width: 75%;
    }
    .col-xl-10 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 83.333333%;
          -ms-flex: 0 0 83.333333%;
              flex: 0 0 83.333333%;
      max-width: 83.333333%;
    }
    .col-xl-11 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 91.666667%;
          -ms-flex: 0 0 91.666667%;
              flex: 0 0 91.666667%;
      max-width: 91.666667%;
    }
    .col-xl-12 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 100%;
          -ms-flex: 0 0 100%;
              flex: 0 0 100%;
      max-width: 100%;
    }
    .pull-xl-0 {
      right: auto;
    }
    .pull-xl-1 {
      right: 8.333333%;
    }
    .pull-xl-2 {
      right: 16.666667%;
    }
    .pull-xl-3 {
      right: 25%;
    }
    .pull-xl-4 {
      right: 33.333333%;
    }
    .pull-xl-5 {
      right: 41.666667%;
    }
    .pull-xl-6 {
      right: 50%;
    }
    .pull-xl-7 {
      right: 58.333333%;
    }
    .pull-xl-8 {
      right: 66.666667%;
    }
    .pull-xl-9 {
      right: 75%;
    }
    .pull-xl-10 {
      right: 83.333333%;
    }
    .pull-xl-11 {
      right: 91.666667%;
    }
    .pull-xl-12 {
      right: 100%;
    }
    .push-xl-0 {
      left: auto;
    }
    .push-xl-1 {
      left: 8.333333%;
    }
    .push-xl-2 {
      left: 16.666667%;
    }
    .push-xl-3 {
      left: 25%;
    }
    .push-xl-4 {
      left: 33.333333%;
    }
    .push-xl-5 {
      left: 41.666667%;
    }
    .push-xl-6 {
      left: 50%;
    }
    .push-xl-7 {
      left: 58.333333%;
    }
    .push-xl-8 {
      left: 66.666667%;
    }
    .push-xl-9 {
      left: 75%;
    }
    .push-xl-10 {
      left: 83.333333%;
    }
    .push-xl-11 {
      left: 91.666667%;
    }
    .push-xl-12 {
      left: 100%;
    }
    .offset-xl-0 {
      margin-left: 0%;
    }
    .offset-xl-1 {
      margin-left: 8.333333%;
    }
    .offset-xl-2 {
      margin-left: 16.666667%;
    }
    .offset-xl-3 {
      margin-left: 25%;
    }
    .offset-xl-4 {
      margin-left: 33.333333%;
    }
    .offset-xl-5 {
      margin-left: 41.666667%;
    }
    .offset-xl-6 {
      margin-left: 50%;
    }
    .offset-xl-7 {
      margin-left: 58.333333%;
    }
    .offset-xl-8 {
      margin-left: 66.666667%;
    }
    .offset-xl-9 {
      margin-left: 75%;
    }
    .offset-xl-10 {
      margin-left: 83.333333%;
    }
    .offset-xl-11 {
      margin-left: 91.666667%;
    }
  }
  
  .order-first {
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
        -ms-flex-order: -1;
            order: -1;
  }
  
  .order-last {
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
        -ms-flex-order: 1;
            order: 1;
  }
  
  .order-0 {
    -webkit-box-ordinal-group: 1;
    -webkit-order: 0;
        -ms-flex-order: 0;
            order: 0;
  }
  
  .flex-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -webkit-flex-direction: row !important;
        -ms-flex-direction: row !important;
            flex-direction: row !important;
  }
  
  .flex-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -webkit-flex-direction: column !important;
        -ms-flex-direction: column !important;
            flex-direction: column !important;
  }
  
  .flex-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -webkit-flex-direction: row-reverse !important;
        -ms-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important;
  }
  
  .flex-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -webkit-flex-direction: column-reverse !important;
        -ms-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important;
  }
  
  .flex-wrap {
    -webkit-flex-wrap: wrap !important;
        -ms-flex-wrap: wrap !important;
            flex-wrap: wrap !important;
  }
  
  .flex-nowrap {
    -webkit-flex-wrap: nowrap !important;
        -ms-flex-wrap: nowrap !important;
            flex-wrap: nowrap !important;
  }
  
  .flex-wrap-reverse {
    -webkit-flex-wrap: wrap-reverse !important;
        -ms-flex-wrap: wrap-reverse !important;
            flex-wrap: wrap-reverse !important;
  }
  
  .justify-content-start {
    -webkit-box-pack: start !important;
    -webkit-justify-content: flex-start !important;
        -ms-flex-pack: start !important;
            justify-content: flex-start !important;
  }
  
  .justify-content-end {
    -webkit-box-pack: end !important;
    -webkit-justify-content: flex-end !important;
        -ms-flex-pack: end !important;
            justify-content: flex-end !important;
  }
  
  .justify-content-center {
    -webkit-box-pack: center !important;
    -webkit-justify-content: center !important;
        -ms-flex-pack: center !important;
            justify-content: center !important;
  }
  
  .justify-content-between {
    -webkit-box-pack: justify !important;
    -webkit-justify-content: space-between !important;
        -ms-flex-pack: justify !important;
            justify-content: space-between !important;
  }
  
  .justify-content-around {
    -webkit-justify-content: space-around !important;
        -ms-flex-pack: distribute !important;
            justify-content: space-around !important;
  }
  
  .align-items-start {
    -webkit-box-align: start !important;
    -webkit-align-items: flex-start !important;
        -ms-flex-align: start !important;
            align-items: flex-start !important;
  }
  
  .align-items-end {
    -webkit-box-align: end !important;
    -webkit-align-items: flex-end !important;
        -ms-flex-align: end !important;
            align-items: flex-end !important;
  }
  
  .align-items-center {
    -webkit-box-align: center !important;
    -webkit-align-items: center !important;
        -ms-flex-align: center !important;
            align-items: center !important;
  }
  
  .align-items-baseline {
    -webkit-box-align: baseline !important;
    -webkit-align-items: baseline !important;
        -ms-flex-align: baseline !important;
            align-items: baseline !important;
  }
  
  .align-items-stretch {
    -webkit-box-align: stretch !important;
    -webkit-align-items: stretch !important;
        -ms-flex-align: stretch !important;
            align-items: stretch !important;
  }
  
  .align-content-start {
    -webkit-align-content: flex-start !important;
        -ms-flex-line-pack: start !important;
            align-content: flex-start !important;
  }
  
  .align-content-end {
    -webkit-align-content: flex-end !important;
        -ms-flex-line-pack: end !important;
            align-content: flex-end !important;
  }
  
  .align-content-center {
    -webkit-align-content: center !important;
        -ms-flex-line-pack: center !important;
            align-content: center !important;
  }
  
  .align-content-between {
    -webkit-align-content: space-between !important;
        -ms-flex-line-pack: justify !important;
            align-content: space-between !important;
  }
  
  .align-content-around {
    -webkit-align-content: space-around !important;
        -ms-flex-line-pack: distribute !important;
            align-content: space-around !important;
  }
  
  .align-content-stretch {
    -webkit-align-content: stretch !important;
        -ms-flex-line-pack: stretch !important;
            align-content: stretch !important;
  }
  
  .align-self-auto {
    -webkit-align-self: auto !important;
        -ms-flex-item-align: auto !important;
            align-self: auto !important;
  }
  
  .align-self-start {
    -webkit-align-self: flex-start !important;
        -ms-flex-item-align: start !important;
            align-self: flex-start !important;
  }
  
  .align-self-end {
    -webkit-align-self: flex-end !important;
        -ms-flex-item-align: end !important;
            align-self: flex-end !important;
  }
  
  .align-self-center {
    -webkit-align-self: center !important;
        -ms-flex-item-align: center !important;
            align-self: center !important;
  }
  
  .align-self-baseline {
    -webkit-align-self: baseline !important;
        -ms-flex-item-align: baseline !important;
            align-self: baseline !important;
  }
  
  .align-self-stretch {
    -webkit-align-self: stretch !important;
        -ms-flex-item-align: stretch !important;
            align-self: stretch !important;
  }
  
  @media (min-width: 576px) {
    .order-sm-first {
      -webkit-box-ordinal-group: 0;
      -webkit-order: -1;
          -ms-flex-order: -1;
              order: -1;
    }
    .order-sm-last {
      -webkit-box-ordinal-group: 2;
      -webkit-order: 1;
          -ms-flex-order: 1;
              order: 1;
    }
    .order-sm-0 {
      -webkit-box-ordinal-group: 1;
      -webkit-order: 0;
          -ms-flex-order: 0;
              order: 0;
    }
    .flex-sm-row {
      -webkit-box-orient: horizontal !important;
      -webkit-box-direction: normal !important;
      -webkit-flex-direction: row !important;
          -ms-flex-direction: row !important;
              flex-direction: row !important;
    }
    .flex-sm-column {
      -webkit-box-orient: vertical !important;
      -webkit-box-direction: normal !important;
      -webkit-flex-direction: column !important;
          -ms-flex-direction: column !important;
              flex-direction: column !important;
    }
    .flex-sm-row-reverse {
      -webkit-box-orient: horizontal !important;
      -webkit-box-direction: reverse !important;
      -webkit-flex-direction: row-reverse !important;
          -ms-flex-direction: row-reverse !important;
              flex-direction: row-reverse !important;
    }
    .flex-sm-column-reverse {
      -webkit-box-orient: vertical !important;
      -webkit-box-direction: reverse !important;
      -webkit-flex-direction: column-reverse !important;
          -ms-flex-direction: column-reverse !important;
              flex-direction: column-reverse !important;
    }
    .flex-sm-wrap {
      -webkit-flex-wrap: wrap !important;
          -ms-flex-wrap: wrap !important;
              flex-wrap: wrap !important;
    }
    .flex-sm-nowrap {
      -webkit-flex-wrap: nowrap !important;
          -ms-flex-wrap: nowrap !important;
              flex-wrap: nowrap !important;
    }
    .flex-sm-wrap-reverse {
      -webkit-flex-wrap: wrap-reverse !important;
          -ms-flex-wrap: wrap-reverse !important;
              flex-wrap: wrap-reverse !important;
    }
    .justify-content-sm-start {
      -webkit-box-pack: start !important;
      -webkit-justify-content: flex-start !important;
          -ms-flex-pack: start !important;
              justify-content: flex-start !important;
    }
    .justify-content-sm-end {
      -webkit-box-pack: end !important;
      -webkit-justify-content: flex-end !important;
          -ms-flex-pack: end !important;
              justify-content: flex-end !important;
    }
    .justify-content-sm-center {
      -webkit-box-pack: center !important;
      -webkit-justify-content: center !important;
          -ms-flex-pack: center !important;
              justify-content: center !important;
    }
    .justify-content-sm-between {
      -webkit-box-pack: justify !important;
      -webkit-justify-content: space-between !important;
          -ms-flex-pack: justify !important;
              justify-content: space-between !important;
    }
    .justify-content-sm-around {
      -webkit-justify-content: space-around !important;
          -ms-flex-pack: distribute !important;
              justify-content: space-around !important;
    }
    .align-items-sm-start {
      -webkit-box-align: start !important;
      -webkit-align-items: flex-start !important;
          -ms-flex-align: start !important;
              align-items: flex-start !important;
    }
    .align-items-sm-end {
      -webkit-box-align: end !important;
      -webkit-align-items: flex-end !important;
          -ms-flex-align: end !important;
              align-items: flex-end !important;
    }
    .align-items-sm-center {
      -webkit-box-align: center !important;
      -webkit-align-items: center !important;
          -ms-flex-align: center !important;
              align-items: center !important;
    }
    .align-items-sm-baseline {
      -webkit-box-align: baseline !important;
      -webkit-align-items: baseline !important;
          -ms-flex-align: baseline !important;
              align-items: baseline !important;
    }
    .align-items-sm-stretch {
      -webkit-box-align: stretch !important;
      -webkit-align-items: stretch !important;
          -ms-flex-align: stretch !important;
              align-items: stretch !important;
    }
    .align-content-sm-start {
      -webkit-align-content: flex-start !important;
          -ms-flex-line-pack: start !important;
              align-content: flex-start !important;
    }
    .align-content-sm-end {
      -webkit-align-content: flex-end !important;
          -ms-flex-line-pack: end !important;
              align-content: flex-end !important;
    }
    .align-content-sm-center {
      -webkit-align-content: center !important;
          -ms-flex-line-pack: center !important;
              align-content: center !important;
    }
    .align-content-sm-between {
      -webkit-align-content: space-between !important;
          -ms-flex-line-pack: justify !important;
              align-content: space-between !important;
    }
    .align-content-sm-around {
      -webkit-align-content: space-around !important;
          -ms-flex-line-pack: distribute !important;
              align-content: space-around !important;
    }
    .align-content-sm-stretch {
      -webkit-align-content: stretch !important;
          -ms-flex-line-pack: stretch !important;
              align-content: stretch !important;
    }
    .align-self-sm-auto {
      -webkit-align-self: auto !important;
          -ms-flex-item-align: auto !important;
              align-self: auto !important;
    }
    .align-self-sm-start {
      -webkit-align-self: flex-start !important;
          -ms-flex-item-align: start !important;
              align-self: flex-start !important;
    }
    .align-self-sm-end {
      -webkit-align-self: flex-end !important;
          -ms-flex-item-align: end !important;
              align-self: flex-end !important;
    }
    .align-self-sm-center {
      -webkit-align-self: center !important;
          -ms-flex-item-align: center !important;
              align-self: center !important;
    }
    .align-self-sm-baseline {
      -webkit-align-self: baseline !important;
          -ms-flex-item-align: baseline !important;
              align-self: baseline !important;
    }
    .align-self-sm-stretch {
      -webkit-align-self: stretch !important;
          -ms-flex-item-align: stretch !important;
              align-self: stretch !important;
    }
  }
  
  @media (min-width: 768px) {
    .order-md-first {
      -webkit-box-ordinal-group: 0;
      -webkit-order: -1;
          -ms-flex-order: -1;
              order: -1;
    }
    .order-md-last {
      -webkit-box-ordinal-group: 2;
      -webkit-order: 1;
          -ms-flex-order: 1;
              order: 1;
    }
    .order-md-0 {
      -webkit-box-ordinal-group: 1;
      -webkit-order: 0;
          -ms-flex-order: 0;
              order: 0;
    }
    .flex-md-row {
      -webkit-box-orient: horizontal !important;
      -webkit-box-direction: normal !important;
      -webkit-flex-direction: row !important;
          -ms-flex-direction: row !important;
              flex-direction: row !important;
    }
    .flex-md-column {
      -webkit-box-orient: vertical !important;
      -webkit-box-direction: normal !important;
      -webkit-flex-direction: column !important;
          -ms-flex-direction: column !important;
              flex-direction: column !important;
    }
    .flex-md-row-reverse {
      -webkit-box-orient: horizontal !important;
      -webkit-box-direction: reverse !important;
      -webkit-flex-direction: row-reverse !important;
          -ms-flex-direction: row-reverse !important;
              flex-direction: row-reverse !important;
    }
    .flex-md-column-reverse {
      -webkit-box-orient: vertical !important;
      -webkit-box-direction: reverse !important;
      -webkit-flex-direction: column-reverse !important;
          -ms-flex-direction: column-reverse !important;
              flex-direction: column-reverse !important;
    }
    .flex-md-wrap {
      -webkit-flex-wrap: wrap !important;
          -ms-flex-wrap: wrap !important;
              flex-wrap: wrap !important;
    }
    .flex-md-nowrap {
      -webkit-flex-wrap: nowrap !important;
          -ms-flex-wrap: nowrap !important;
              flex-wrap: nowrap !important;
    }
    .flex-md-wrap-reverse {
      -webkit-flex-wrap: wrap-reverse !important;
          -ms-flex-wrap: wrap-reverse !important;
              flex-wrap: wrap-reverse !important;
    }
    .justify-content-md-start {
      -webkit-box-pack: start !important;
      -webkit-justify-content: flex-start !important;
          -ms-flex-pack: start !important;
              justify-content: flex-start !important;
    }
    .justify-content-md-end {
      -webkit-box-pack: end !important;
      -webkit-justify-content: flex-end !important;
          -ms-flex-pack: end !important;
              justify-content: flex-end !important;
    }
    .justify-content-md-center {
      -webkit-box-pack: center !important;
      -webkit-justify-content: center !important;
          -ms-flex-pack: center !important;
              justify-content: center !important;
    }
    .justify-content-md-between {
      -webkit-box-pack: justify !important;
      -webkit-justify-content: space-between !important;
          -ms-flex-pack: justify !important;
              justify-content: space-between !important;
    }
    .justify-content-md-around {
      -webkit-justify-content: space-around !important;
          -ms-flex-pack: distribute !important;
              justify-content: space-around !important;
    }
    .align-items-md-start {
      -webkit-box-align: start !important;
      -webkit-align-items: flex-start !important;
          -ms-flex-align: start !important;
              align-items: flex-start !important;
    }
    .align-items-md-end {
      -webkit-box-align: end !important;
      -webkit-align-items: flex-end !important;
          -ms-flex-align: end !important;
              align-items: flex-end !important;
    }
    .align-items-md-center {
      -webkit-box-align: center !important;
      -webkit-align-items: center !important;
          -ms-flex-align: center !important;
              align-items: center !important;
    }
    .align-items-md-baseline {
      -webkit-box-align: baseline !important;
      -webkit-align-items: baseline !important;
          -ms-flex-align: baseline !important;
              align-items: baseline !important;
    }
    .align-items-md-stretch {
      -webkit-box-align: stretch !important;
      -webkit-align-items: stretch !important;
          -ms-flex-align: stretch !important;
              align-items: stretch !important;
    }
    .align-content-md-start {
      -webkit-align-content: flex-start !important;
          -ms-flex-line-pack: start !important;
              align-content: flex-start !important;
    }
    .align-content-md-end {
      -webkit-align-content: flex-end !important;
          -ms-flex-line-pack: end !important;
              align-content: flex-end !important;
    }
    .align-content-md-center {
      -webkit-align-content: center !important;
          -ms-flex-line-pack: center !important;
              align-content: center !important;
    }
    .align-content-md-between {
      -webkit-align-content: space-between !important;
          -ms-flex-line-pack: justify !important;
              align-content: space-between !important;
    }
    .align-content-md-around {
      -webkit-align-content: space-around !important;
          -ms-flex-line-pack: distribute !important;
              align-content: space-around !important;
    }
    .align-content-md-stretch {
      -webkit-align-content: stretch !important;
          -ms-flex-line-pack: stretch !important;
              align-content: stretch !important;
    }
    .align-self-md-auto {
      -webkit-align-self: auto !important;
          -ms-flex-item-align: auto !important;
              align-self: auto !important;
    }
    .align-self-md-start {
      -webkit-align-self: flex-start !important;
          -ms-flex-item-align: start !important;
              align-self: flex-start !important;
    }
    .align-self-md-end {
      -webkit-align-self: flex-end !important;
          -ms-flex-item-align: end !important;
              align-self: flex-end !important;
    }
    .align-self-md-center {
      -webkit-align-self: center !important;
          -ms-flex-item-align: center !important;
              align-self: center !important;
    }
    .align-self-md-baseline {
      -webkit-align-self: baseline !important;
          -ms-flex-item-align: baseline !important;
              align-self: baseline !important;
    }
    .align-self-md-stretch {
      -webkit-align-self: stretch !important;
          -ms-flex-item-align: stretch !important;
              align-self: stretch !important;
    }
  }
  
  @media (min-width: 992px) {
    .order-lg-first {
      -webkit-box-ordinal-group: 0;
      -webkit-order: -1;
          -ms-flex-order: -1;
              order: -1;
    }
    .order-lg-last {
      -webkit-box-ordinal-group: 2;
      -webkit-order: 1;
          -ms-flex-order: 1;
              order: 1;
    }
    .order-lg-0 {
      -webkit-box-ordinal-group: 1;
      -webkit-order: 0;
          -ms-flex-order: 0;
              order: 0;
    }
    .flex-lg-row {
      -webkit-box-orient: horizontal !important;
      -webkit-box-direction: normal !important;
      -webkit-flex-direction: row !important;
          -ms-flex-direction: row !important;
              flex-direction: row !important;
    }
    .flex-lg-column {
      -webkit-box-orient: vertical !important;
      -webkit-box-direction: normal !important;
      -webkit-flex-direction: column !important;
          -ms-flex-direction: column !important;
              flex-direction: column !important;
    }
    .flex-lg-row-reverse {
      -webkit-box-orient: horizontal !important;
      -webkit-box-direction: reverse !important;
      -webkit-flex-direction: row-reverse !important;
          -ms-flex-direction: row-reverse !important;
              flex-direction: row-reverse !important;
    }
    .flex-lg-column-reverse {
      -webkit-box-orient: vertical !important;
      -webkit-box-direction: reverse !important;
      -webkit-flex-direction: column-reverse !important;
          -ms-flex-direction: column-reverse !important;
              flex-direction: column-reverse !important;
    }
    .flex-lg-wrap {
      -webkit-flex-wrap: wrap !important;
          -ms-flex-wrap: wrap !important;
              flex-wrap: wrap !important;
    }
    .flex-lg-nowrap {
      -webkit-flex-wrap: nowrap !important;
          -ms-flex-wrap: nowrap !important;
              flex-wrap: nowrap !important;
    }
    .flex-lg-wrap-reverse {
      -webkit-flex-wrap: wrap-reverse !important;
          -ms-flex-wrap: wrap-reverse !important;
              flex-wrap: wrap-reverse !important;
    }
    .justify-content-lg-start {
      -webkit-box-pack: start !important;
      -webkit-justify-content: flex-start !important;
          -ms-flex-pack: start !important;
              justify-content: flex-start !important;
    }
    .justify-content-lg-end {
      -webkit-box-pack: end !important;
      -webkit-justify-content: flex-end !important;
          -ms-flex-pack: end !important;
              justify-content: flex-end !important;
    }
    .justify-content-lg-center {
      -webkit-box-pack: center !important;
      -webkit-justify-content: center !important;
          -ms-flex-pack: center !important;
              justify-content: center !important;
    }
    .justify-content-lg-between {
      -webkit-box-pack: justify !important;
      -webkit-justify-content: space-between !important;
          -ms-flex-pack: justify !important;
              justify-content: space-between !important;
    }
    .justify-content-lg-around {
      -webkit-justify-content: space-around !important;
          -ms-flex-pack: distribute !important;
              justify-content: space-around !important;
    }
    .align-items-lg-start {
      -webkit-box-align: start !important;
      -webkit-align-items: flex-start !important;
          -ms-flex-align: start !important;
              align-items: flex-start !important;
    }
    .align-items-lg-end {
      -webkit-box-align: end !important;
      -webkit-align-items: flex-end !important;
          -ms-flex-align: end !important;
              align-items: flex-end !important;
    }
    .align-items-lg-center {
      -webkit-box-align: center !important;
      -webkit-align-items: center !important;
          -ms-flex-align: center !important;
              align-items: center !important;
    }
    .align-items-lg-baseline {
      -webkit-box-align: baseline !important;
      -webkit-align-items: baseline !important;
          -ms-flex-align: baseline !important;
              align-items: baseline !important;
    }
    .align-items-lg-stretch {
      -webkit-box-align: stretch !important;
      -webkit-align-items: stretch !important;
          -ms-flex-align: stretch !important;
              align-items: stretch !important;
    }
    .align-content-lg-start {
      -webkit-align-content: flex-start !important;
          -ms-flex-line-pack: start !important;
              align-content: flex-start !important;
    }
    .align-content-lg-end {
      -webkit-align-content: flex-end !important;
          -ms-flex-line-pack: end !important;
              align-content: flex-end !important;
    }
    .align-content-lg-center {
      -webkit-align-content: center !important;
          -ms-flex-line-pack: center !important;
              align-content: center !important;
    }
    .align-content-lg-between {
      -webkit-align-content: space-between !important;
          -ms-flex-line-pack: justify !important;
              align-content: space-between !important;
    }
    .align-content-lg-around {
      -webkit-align-content: space-around !important;
          -ms-flex-line-pack: distribute !important;
              align-content: space-around !important;
    }
    .align-content-lg-stretch {
      -webkit-align-content: stretch !important;
          -ms-flex-line-pack: stretch !important;
              align-content: stretch !important;
    }
    .align-self-lg-auto {
      -webkit-align-self: auto !important;
          -ms-flex-item-align: auto !important;
              align-self: auto !important;
    }
    .align-self-lg-start {
      -webkit-align-self: flex-start !important;
          -ms-flex-item-align: start !important;
              align-self: flex-start !important;
    }
    .align-self-lg-end {
      -webkit-align-self: flex-end !important;
          -ms-flex-item-align: end !important;
              align-self: flex-end !important;
    }
    .align-self-lg-center {
      -webkit-align-self: center !important;
          -ms-flex-item-align: center !important;
              align-self: center !important;
    }
    .align-self-lg-baseline {
      -webkit-align-self: baseline !important;
          -ms-flex-item-align: baseline !important;
              align-self: baseline !important;
    }
    .align-self-lg-stretch {
      -webkit-align-self: stretch !important;
          -ms-flex-item-align: stretch !important;
              align-self: stretch !important;
    }
  }
  
  @media (min-width: 1200px) {
    .order-xl-first {
      -webkit-box-ordinal-group: 0;
      -webkit-order: -1;
          -ms-flex-order: -1;
              order: -1;
    }
    .order-xl-last {
      -webkit-box-ordinal-group: 2;
      -webkit-order: 1;
          -ms-flex-order: 1;
              order: 1;
    }
    .order-xl-0 {
      -webkit-box-ordinal-group: 1;
      -webkit-order: 0;
          -ms-flex-order: 0;
              order: 0;
    }
    .flex-xl-row {
      -webkit-box-orient: horizontal !important;
      -webkit-box-direction: normal !important;
      -webkit-flex-direction: row !important;
          -ms-flex-direction: row !important;
              flex-direction: row !important;
    }
    .flex-xl-column {
      -webkit-box-orient: vertical !important;
      -webkit-box-direction: normal !important;
      -webkit-flex-direction: column !important;
          -ms-flex-direction: column !important;
              flex-direction: column !important;
    }
    .flex-xl-row-reverse {
      -webkit-box-orient: horizontal !important;
      -webkit-box-direction: reverse !important;
      -webkit-flex-direction: row-reverse !important;
          -ms-flex-direction: row-reverse !important;
              flex-direction: row-reverse !important;
    }
    .flex-xl-column-reverse {
      -webkit-box-orient: vertical !important;
      -webkit-box-direction: reverse !important;
      -webkit-flex-direction: column-reverse !important;
          -ms-flex-direction: column-reverse !important;
              flex-direction: column-reverse !important;
    }
    .flex-xl-wrap {
      -webkit-flex-wrap: wrap !important;
          -ms-flex-wrap: wrap !important;
              flex-wrap: wrap !important;
    }
    .flex-xl-nowrap {
      -webkit-flex-wrap: nowrap !important;
          -ms-flex-wrap: nowrap !important;
              flex-wrap: nowrap !important;
    }
    .flex-xl-wrap-reverse {
      -webkit-flex-wrap: wrap-reverse !important;
          -ms-flex-wrap: wrap-reverse !important;
              flex-wrap: wrap-reverse !important;
    }
    .justify-content-xl-start {
      -webkit-box-pack: start !important;
      -webkit-justify-content: flex-start !important;
          -ms-flex-pack: start !important;
              justify-content: flex-start !important;
    }
    .justify-content-xl-end {
      -webkit-box-pack: end !important;
      -webkit-justify-content: flex-end !important;
          -ms-flex-pack: end !important;
              justify-content: flex-end !important;
    }
    .justify-content-xl-center {
      -webkit-box-pack: center !important;
      -webkit-justify-content: center !important;
          -ms-flex-pack: center !important;
              justify-content: center !important;
    }
    .justify-content-xl-between {
      -webkit-box-pack: justify !important;
      -webkit-justify-content: space-between !important;
          -ms-flex-pack: justify !important;
              justify-content: space-between !important;
    }
    .justify-content-xl-around {
      -webkit-justify-content: space-around !important;
          -ms-flex-pack: distribute !important;
              justify-content: space-around !important;
    }
    .align-items-xl-start {
      -webkit-box-align: start !important;
      -webkit-align-items: flex-start !important;
          -ms-flex-align: start !important;
              align-items: flex-start !important;
    }
    .align-items-xl-end {
      -webkit-box-align: end !important;
      -webkit-align-items: flex-end !important;
          -ms-flex-align: end !important;
              align-items: flex-end !important;
    }
    .align-items-xl-center {
      -webkit-box-align: center !important;
      -webkit-align-items: center !important;
          -ms-flex-align: center !important;
              align-items: center !important;
    }
    .align-items-xl-baseline {
      -webkit-box-align: baseline !important;
      -webkit-align-items: baseline !important;
          -ms-flex-align: baseline !important;
              align-items: baseline !important;
    }
    .align-items-xl-stretch {
      -webkit-box-align: stretch !important;
      -webkit-align-items: stretch !important;
          -ms-flex-align: stretch !important;
              align-items: stretch !important;
    }
    .align-content-xl-start {
      -webkit-align-content: flex-start !important;
          -ms-flex-line-pack: start !important;
              align-content: flex-start !important;
    }
    .align-content-xl-end {
      -webkit-align-content: flex-end !important;
          -ms-flex-line-pack: end !important;
              align-content: flex-end !important;
    }
    .align-content-xl-center {
      -webkit-align-content: center !important;
          -ms-flex-line-pack: center !important;
              align-content: center !important;
    }
    .align-content-xl-between {
      -webkit-align-content: space-between !important;
          -ms-flex-line-pack: justify !important;
              align-content: space-between !important;
    }
    .align-content-xl-around {
      -webkit-align-content: space-around !important;
          -ms-flex-line-pack: distribute !important;
              align-content: space-around !important;
    }
    .align-content-xl-stretch {
      -webkit-align-content: stretch !important;
          -ms-flex-line-pack: stretch !important;
              align-content: stretch !important;
    }
    .align-self-xl-auto {
      -webkit-align-self: auto !important;
          -ms-flex-item-align: auto !important;
              align-self: auto !important;
    }
    .align-self-xl-start {
      -webkit-align-self: flex-start !important;
          -ms-flex-item-align: start !important;
              align-self: flex-start !important;
    }
    .align-self-xl-end {
      -webkit-align-self: flex-end !important;
          -ms-flex-item-align: end !important;
              align-self: flex-end !important;
    }
    .align-self-xl-center {
      -webkit-align-self: center !important;
          -ms-flex-item-align: center !important;
              align-self: center !important;
    }
    .align-self-xl-baseline {
      -webkit-align-self: baseline !important;
          -ms-flex-item-align: baseline !important;
              align-self: baseline !important;
    }
    .align-self-xl-stretch {
      -webkit-align-self: stretch !important;
          -ms-flex-item-align: stretch !important;
              align-self: stretch !important;
    }
  }
  /*# sourceMappingURL=bootstrap-grid.css.map */


